import React, { Children, useState } from 'react';
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import PButton from './PButton';

const tncList = [
  'Examinees are prohibited from cheating and/or taking exams belonging to others.',
  'Examinees are not allowed to seek answers from books, the internet, and/or other media.',
  'Examinees cannot return to the previous question if they have pressed the <strong>“Next”</strong> button.',
  'Questions that are skipped (not answered) will be automatically considered wrong by the system.',
  'If the exam duration has expired, then the answers will be submitted automatically and <strong>cannot be changed again</strong>.',
  '<strong>Time keeps counting if the examinees log out and log back in.</strong>',
  '<strong>If there is a sudden internet interruption, examinees can still continue the exam using the same browser and device. Using another device or browser then the exam will restart from the last remaining time.</strong>',
  'Examinees who experience problems/interferences during the exam are <strong>required</strong> to take screen captures/records related to these problems/disorders and then immediately report them to the operational team. If you report a problem/disturbance without any proof, then we cannot follow up the problem.',
  'Examinees who are found to have violated all the regulations mentioned above will be given severe sanctions in accordance with applicable policies.',
  'I declare that I have read and agree to all regulations.',
];

const TncModal = ({ toggle, isOpen, testId }) => {
  const [checkedTnc, setCheckedTnc] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      scrollable
      size="lg"
      style={{ maxWidth: '620px' }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center p-4">
        <div className="f-h1-mweb">Terms & Conditions</div>
      </div>
      <ModalBody className="p-4">
        <div style={{ backgroundColor: 'rgb(242, 242, 242)', padding: '16px' }}>
          <div className="f--bold">
            PLEASE READ THE FOLLOWING RULES CAREFULLY BEFORE STARTING THE EXAM!
          </div>
          <ol className="my-3">
            {Children.toArray(
              tncList.map((tnc) => (
                <li style={{ lineHeight: 1.2 }}>{ReactHtmlParser(tnc)}</li>
              )),
            )}
          </ol>
          <div>
            Examinees who are found to have violated all the regulations
            mentioned above will be given severe sanctions in accordance with
            applicable policies.
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4">
          <FormGroup
            check
            inline
            className="mx-auto cursor-pointer"
            onClick={() => setCheckedTnc(!checkedTnc)}
          >
            <Input
              className="pwdk-checkbox-container"
              type="checkbox"
              checked={checkedTnc}
            />
            <Label check>
              I declare that I have read and agree to all regulations.
            </Label>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter className="p-3 d-flex justify-content-center">
        <Link to={`/test/${testId}`} style={{ width: 'fit-content' }}>
          <PButton
            onClick={toggle}
            disabled={!checkedTnc}
            className=" f--16 font-weight-bold"
          >
            Start
          </PButton>
        </Link>
      </ModalFooter>
    </Modal>
  );
};

export default TncModal;
