import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import test from './test';
import result from './result';
import group from './group';

export default combineReducers({
  auth,
  test,
  result,
  group,
});
