import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import cross from './image/ic_close.svg';
import successImage from './image/success_image.svg';

const SuccessModal = ({ toggle, isOpen }) => {
  return (
    <Modal contentClassName="success-modal" isOpen={isOpen} toggle={toggle}>
      <ModalBody className="mx-3 mt-4">
        <div
          className="pwdk-cross-button"
          onClick={toggle}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggle();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <img src={cross} alt="close" className="cross-button" />
        </div>
        <div className="d-flex justify-content-center mb-4">
          <div className="success-modal-header">
            Selamat! Anda telah menyelesaikan quiz!
          </div>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <img src={successImage} alt="success" />
        </div>
        <div className="d-flex justify-content-center mb-4">
          <div className="success-modal-text">
            Selamat! Anda telah menyelesaikan quiz, jawaban Anda telah kami
            rekam untuk proses pengoreksian.
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
