// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { encrypt } from '../../supports/helpers/encryption';
import { appKey } from '../../supports/config';
import { API_URL_1 } from '../../supports/api-url/apiurl';

const initialUser = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  completion: [],
  isAdmin: null,
  group: {},
};

const initialState = {
  user: initialUser,
  loading: false,
  message: '',
  error: false,
  tokenChecked: false,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    setUser: (state, action) => {
      const {
        id,
        username,
        firstName,
        lastName,
        email,
        completion,
        isAdmin,
        group,
      } = action.payload;
      state.user = {
        id,
        username,
        firstName,
        lastName,
        email,
        completion,
        isAdmin,
        group,
      };
    },
    setCompletion: (state, action) => {
      state.user.completion = action.payload;
    },
    logout: (state) => {
      // removes all localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('loggedUsername');
      // localStorage.removeItem('inputs1');
      // localStorage.removeItem('inputs2');

      state.user = initialUser;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload?.message || 'Login Success';
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload.message || 'Login Failed';
      state.user = initialState;
    },
    logoutStart: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.message = 'Logout Success';
    },
    logoutFail: (state) => {
      state.loading = false;
      state.error = true;
      state.message = 'Logout Failed';
    },
    keepLoginStart: (state) => {
      state.loading = true;
    },
    keepLoginSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload?.message || 'Keep Login Success';
      state.tokenChecked = true;
    },
    keepLoginFailed: (state, action) => {
      state.loading = false;
      state.message = action.payload.message || 'Keep Login Failed';
      state.tokenChecked = true;
    },
  },
});

export default reducer;

export const {
  loginStart,
  setUser,
  loginFail,
  loginSuccess,
  logoutStart,
  logout,
  logoutSuccess,
  logoutFail,
  keepLoginStart,
  keepLoginSuccess,
  keepLoginFailed,
  setCompletion,
} = actions;

export const signIn = (data) => {
  return async (dispatch) => {
    dispatch(loginStart());
    const { username, password } = data;
    try {
      const ep = encrypt(appKey, password); // encrypted password
      const res = await Axios.post(`${API_URL_1}/auth/login`, {
        username,
        ep,
      });
      const { token } = res.data.result;

      localStorage.setItem('token', token || null);
      localStorage.setItem('loggedUsername', username);
      dispatch(setUser(res.data.result));
      dispatch(loginSuccess());
    } catch (err) {
      const message = err.response?.data.message || err.message;
      window.alert(message);
      dispatch(loginFail({ message }));
    }
  };
};

export const keepLogin = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');

    dispatch(keepLoginStart());
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await Axios.get(`${API_URL_1}/auth/keep-login`, options);
        dispatch(setUser(res.data.result));
        dispatch(keepLoginSuccess());
      } catch (err) {
        const message = err.response?.data.message || err.message;
        dispatch(keepLoginFailed({ message }));
      }
    } else {
      dispatch(keepLoginFailed({ message: 'No Token' }));
    }
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    try {
      dispatch(loginStart());
      dispatch(logout());
      dispatch(logoutSuccess());
    } catch (err) {
      dispatch(logoutFail());
    }
  };
};
