// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { API_URL_1 } from '../../supports/api-url/apiurl';

const initialState = {
  loading: false,
  completeData: [],
  filteredData: [],
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'result',
  initialState,
  reducers: {
    fetchResultStart: (state) => {
      state.loading = true;
    },
    setCompleteData: (state, action) => {
      state.completeData = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    clearFilteredData: (state) => {
      state.filteredData = [];
    },
    fetchResultSuccess: (state) => {
      state.loading = false;
    },
    fetchResultFailed: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchResultStart,
  fetchResultSuccess,
  fetchResultFailed,
  setCompleteData,
  setFilteredData,
  clearFilteredData,
} = actions;

export const fetchAllResults = () => {
  return async (dispatch) => {
    dispatch(fetchResultStart());
    try {
      const result = await Axios.get(`${API_URL_1}/result`);
      dispatch(setCompleteData(result.data.data));
      dispatch(fetchResultSuccess());
    } catch (err) {
      dispatch(fetchResultFailed());
      window.alert(err);
    }
  };
};

export const findTestResultByUserId = (paramsObject) => {
  return async (dispatch) => {
    dispatch(fetchResultStart());
    try {
      const result = await Axios.get(
        `${API_URL_1}/result/by-userid/${JSON.stringify(paramsObject)}`,
      );
      dispatch(setFilteredData(result.data.data));
      dispatch(fetchResultSuccess());
      if (result.data.data.length === 0) {
        let alertMessage;
        if (paramsObject.groupId !== null) {
          alertMessage = `Can not find this user in this group. \nDisplaying result for all user test.`;
        }
        window.alert(alertMessage);
      }
    } catch (err) {
      dispatch(fetchResultFailed());
      window.alert(err);
    }
  };
};

export const findTestResultByName = (paramsObject) => {
  return async (dispatch) => {
    dispatch(fetchResultStart());
    try {
      const result = await Axios.get(
        `${API_URL_1}/result/by-name/${JSON.stringify(paramsObject)}`,
      );
      dispatch(setFilteredData(result.data.data));
      dispatch(fetchResultSuccess());
      if (result.data.data.length === 0) {
        let alertMessage;
        if (paramsObject.groupId !== null) {
          alertMessage = `Can not find name containing keyword : "${paramsObject.keyword}" in this group. \nDisplaying result for all user test in all groups.`;
        } else {
          alertMessage = `Can not find name containing keyword : "${paramsObject.keyword}". \nDisplaying result for all user test.`;
        }
        window.alert(alertMessage);
      }
    } catch (err) {
      dispatch(fetchResultFailed());
      window.alert(err);
    }
  };
};

export const findTestResultByEmail = (paramsObject) => {
  return async (dispatch) => {
    dispatch(fetchResultStart());
    try {
      const result = await Axios.get(
        `${API_URL_1}/result/by-email/${JSON.stringify(paramsObject)}`,
      );
      dispatch(setFilteredData(result.data.data));
      dispatch(fetchResultSuccess());
      if (result.data.data.length === 0) {
        let alertMessage;
        if (paramsObject.groupId !== null) {
          alertMessage = `Can not find email containing keyword : "${paramsObject.keyword}" in this group. \nDisplaying result for all user test in all groups.`;
        } else {
          alertMessage = `Can not find email containing keyword : "${paramsObject.keyword}". \nDisplaying result for all user test.`;
        }
        window.alert(alertMessage);
      }
    } catch (err) {
      dispatch(fetchResultFailed());
      window.alert(err);
    }
  };
};

export const findTestResultByGroupIdOnly = (groupId) => {
  return async (dispatch) => {
    dispatch(fetchResultStart());
    try {
      const result = await Axios.get(
        `${API_URL_1}/result/by-groupId/${groupId}`,
      );
      dispatch(setFilteredData(result.data.data));
      dispatch(fetchResultSuccess());
      if (result.data.data.length === 0) {
        window.alert(
          `Can not find any user in this group. \nDisplaying result for all user test in all groups.`,
        );
      }
    } catch (err) {
      dispatch(fetchResultFailed());
      window.alert(err);
    }
  };
};

export const resetFilteredData = () => {
  return async (dispatch) => {
    dispatch(clearFilteredData());
  };
};
