import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { API_URL_1 } from '../supports/api-url/apiurl';

const Disclaimer = () => {
  const [disclaimerDetails, setDisclaimerDetails] = useState([]);
  const getDisclaimerDetails = async () => {
    try {
      const { data } = await axios.get(`${API_URL_1}/disclaimer.json`);
      setDisclaimerDetails(data);
    } catch (err) {
      console.log(err);
    }
    return axios.get;
  };

  useEffect(() => {
    getDisclaimerDetails();
  }, []);

  return (
    <Card className="drop-shadow" style={{ maxWidth: '555px', width: '100%' }}>
      <CardBody>
        <div className="d-flex flex-column">
          <div
            className="text-left font-weight-bold pb-3"
            style={{ fontSize: '24px' }}
          >
            Requirement Technical Test
          </div>
          <ul className="text-left pl-4">
            {disclaimerDetails?.map((string) => (
              <li style={{ fontSize: '14px', color: '#505050' }}>{string}</li>
            ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default Disclaimer;
