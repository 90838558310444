import React from 'react';
import { Container, Row, Button } from 'reactstrap';
import './ResultPageStyle.css';

import SearchAndSelectComponent from './SearchAndSelectComponent';
import SearchAndSelectComponentForGroups from './SearchAndSelectComponentForGroups';

const SidebarComponent = ({ propsForSidebarComponent }) => {
  const {
    listlUserEmailForDropdownButton,
    openEmailDropdownButton,
    setOpenEmailDropdownButton,
    inputEmail,
    setInputEmail,
    setInputFullname,
    selectedUserId,
    setSelectedUserId,
    selectedGroupId,
    pushToAddressBar,
    listUserFullnameForDropdownButton,
    openUserFullnameDropdownButton,
    setOpenUserFullnameDropdownButton,
    inputFullname,
    listGroupForDropdownButton,
    openGroupDropdownButton,
    setOpenGroupDropdownButton,
    setSelectedGroupId,
    onSearchData,
    onResetFilter,
  } = propsForSidebarComponent;

  return (
    <div className="resultPage-sideNav">
      <Container>
        <Row className="resultPage-sideNav-row">Select user group</Row>
        <Row>
          <SearchAndSelectComponentForGroups
            dropdownOptionsData={listGroupForDropdownButton}
            isOpen={openGroupDropdownButton}
            toggleDropDown={() =>
              setOpenGroupDropdownButton(!openGroupDropdownButton)
            }
            selectedGroupId={selectedGroupId}
            selectedUserId={selectedUserId}
            setSelectedGroupId={setSelectedGroupId}
            inputFullname={inputFullname}
            inputEmail={inputEmail}
            pushToAddressBar={pushToAddressBar}
          />
        </Row>
        <Row className="resultPage-sideNav-row">Select or search by name</Row>
        <Row>
          <SearchAndSelectComponent
            dropdownOptionsData={listUserFullnameForDropdownButton}
            isOpen={openUserFullnameDropdownButton}
            toggleDropDown={() =>
              setOpenUserFullnameDropdownButton(!openUserFullnameDropdownButton)
            }
            inputBoxField="fullName"
            inputBoxValue={inputFullname}
            setInputBoxValue={setInputFullname}
            clearAnotherInputBox={() => setInputEmail('')}
            clearAllInputBox={() => {
              setInputFullname('');
              setInputEmail('');
            }}
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            selectedGroupId={selectedGroupId}
            pushToAddressBar={pushToAddressBar}
          />
        </Row>
        <Row className="resultPage-sideNav-row">Select or search by email</Row>
        <Row>
          <SearchAndSelectComponent
            dropdownOptionsData={listlUserEmailForDropdownButton}
            isOpen={openEmailDropdownButton}
            toggleDropDown={() =>
              setOpenEmailDropdownButton(!openEmailDropdownButton)
            }
            inputBoxField="email"
            inputBoxValue={inputEmail}
            setInputBoxValue={setInputEmail}
            clearAnotherInputBox={() => setInputFullname('')}
            clearAllInputBox={() => {
              setInputFullname('');
              setInputEmail('');
            }}
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            selectedGroupId={selectedGroupId}
            pushToAddressBar={pushToAddressBar}
          />
        </Row>
        <Row className="resultPage-sideNav-lastRow">
          <Button
            className="resultPage-sideNav-lastRow-button"
            onClick={() => onSearchData()}
          >
            Search
          </Button>
          <Button
            className="resultPage-sideNav-lastRow-button"
            onClick={() => onResetFilter()}
          >
            Clear
          </Button>
        </Row>
      </Container>
    </div>
  );
};

export default SidebarComponent;
