import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PButton from './PButton';

const SkipModal = ({ toggle, isOpen, handleSkipQuestions }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      scrollable
      size="lg"
      style={{ maxWidth: '600px' }}
      backdrop="static"
    >
      <div className="d-flex flex-row justify-content-between align-items-center p-4">
        <div className="f-h1-mweb">Unanswered Questions</div>
      </div>
      <ModalBody className="p-4">
        <div className="f-mweb-h5 mb-2">
          Are you sure you want to leave your answer blank?
        </div>
        <div className="f-14" style={{ color: 'rgb(130, 130, 130)' }}>
          After you go through this question without an answer, you can&apos;t
          go back and will automatically be considered wrong by the system.
        </div>
      </ModalBody>
      <ModalFooter className="p-4 d-flex flex-column justify-content-center">
        <PButton
          onClick={handleSkipQuestions}
          style={{ width: '100%' }}
          className=" f--16 font-weight-bold"
        >
          Skip
        </PButton>
        <PButton
          onClick={toggle}
          style={{ width: '100%' }}
          color="nude"
          className=" f--16 font-weight-bold"
        >
          Cancel
        </PButton>
      </ModalFooter>
    </Modal>
  );
};

export default SkipModal;
