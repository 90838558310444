import React, { useState, useEffect } from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { Progress, Nav, NavItem, NavLink } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogout } from '../redux/modules/auth';

import menu from './image/ic_menuburger.svg';
import ic_close from './image/ic_close_menu.svg';
import ic_logout from './image/ic_logout.svg';
import ic_user from './image/ic_user.svg';
import Logo from './image/logo.svg';

export default () => {
  const user = useSelector((state) => state.auth.user);
  const { countAnswer } = useSelector((state) => state.test);
  const dataTest = useSelector((state) => state.test.data);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [countUserAnswer, setCountUserAnswer] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (dataTest) {
      setCountUserAnswer(countAnswer);
    }
  }, [dataTest, countAnswer]);

  const renderNavbarTitle = () => {
    if (location.pathname.includes('/test')) {
      return (
        <div className="d-flex flex-row">
          <Link to="/" style={{ textDecoration: 'none', zIndex: '1003' }}>
            <div className="test-navbar">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
          <div className="test-navbar-title" style={{ color: '#6e6e6e' }}>
            {dataTest.title}
          </div>
        </div>
      );
    }

    return (
      <Link to="/" style={{ textDecoration: 'none' }}>
        <div className="nav-brand">
          <img src={Logo} alt="logo" />
        </div>
      </Link>
    );
  };

  const onClickLogout = () => {
    setDropdownActive(false);
    dispatch(handleLogout());
    return <Redirect to="/" />;
  };

  const renderNavbarContent = () => {
    if (location.pathname.includes('/test')) {
      return (
        <div className="d-flex flex-row class-content">
          <div className="class-progress p-2" style={{ width: '100%' }}>
            <div className="d-flex flex-row">
              <div className="f-md-body-small f--dark-gray font-weight-bold">
                PROGRESS QUIZ
              </div>
              <div className="ml-auto f-md-body-small">
                {Math.round((countUserAnswer / dataTest.totalQuestions) * 100)}/
                100 %
              </div>
            </div>
            <div className="mt-1">
              <Progress
                value={Math.round(
                  (countUserAnswer / dataTest.totalQuestions) * 100,
                )}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      );
    }

    if (user.isAdmin) {
      return (
        <Nav className="mr-auto pr-5 my-auto d-flex flex-row mb-0" navbar>
          <NavItem className="mr-4">
            <NavLink
              className="f--dark-gray"
              tag={Link}
              to="/admin/register-user"
            >
              Register User
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="f--dark-gray" tag={Link} to="/admin/result">
              Results
            </NavLink>
          </NavItem>
        </Nav>
      );
    }

    return <div />;
  };

  const RenderOverlay = () => {
    return (
      <div
        role="button"
        tabIndex={0}
        label="overlay"
        onClick={() => setDropdownActive(!dropdownActive)}
        onKeyDown={() => setDropdownActive(!dropdownActive)}
        className={`overlay ${dropdownActive ? 'show-navbar' : ''}`}
      />
    );
  };

  const RenderMenuLogout = () => {
    return (
      <div style={{ height: '100%' }}>
        <div
          role="button"
          tabIndex={0}
          className="navbar-button-tab cursor-pointer"
          style={{ width: '100%', outline: '0' }}
          onClick={() => setDropdownActive(!dropdownActive)}
          onKeyDown={() => setDropdownActive(!dropdownActive)}
        >
          <img src={dropdownActive ? ic_close : menu} alt="menu-burger" />
        </div>
        <div
          className={`container-nav ${
            dropdownActive ? 'fade-in' : 'fade-out'
          } `}
        >
          <div className="profile-nav-border-bottom">
            <div className="d-flex flex-row py-4 px-4 text-left">
              <img
                src={ic_user}
                className="user-profile-icon mr-3"
                alt="ic_user"
              />
              <div className="d-flex flex-column">
                <h6 className="mb-0 font-weight-bold">
                  {user.firstName} {user.lastName}
                </h6>
                <p className="mb-0">{user.email}</p>
              </div>
            </div>
          </div>
          <div>
            <div
              role="button"
              tabIndex={0}
              className="d-flex flex-row py-4 px-4 cursor-pointer"
              onClick={onClickLogout}
              onKeyDown={onClickLogout}
            >
              <img
                src={ic_logout}
                className="navbar-icon pl-3 mr-3"
                alt="ic_user"
              />
              <div className="d-flex flex-column">
                <p className="mb-0">Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <RenderOverlay />
      <div className="global-nav">
        <div className="navbar-green-line" />
        <div className="container-fluid">
          <nav className="nav-root" style={{ width: '100%' }}>
            {renderNavbarTitle()}
            <ul className="ml-auto mb-0">{renderNavbarContent()}</ul>
            {user.id && <RenderMenuLogout />}
          </nav>
        </div>
      </div>
    </div>
  );
};
