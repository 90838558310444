import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import cross from './image/ic_close.svg';
import PButton from './PButton';

const TimeUpModal = ({ toggle, isOpen }) => {
  return (
    <Modal contentClassName="success-modal" isOpen={isOpen} toggle={toggle}>
      <ModalBody className="mx-3 mt-4">
        <div
          className="pwdk-cross-button"
          onClick={toggle}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggle();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <img src={cross} alt="close" className="cross-button" />
        </div>
        <div className="d-flex justify-content-center mb-4">
          <div className="success-modal-header">
            Waktu Pengerjaan Anda Telah Habis
          </div>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <div className="success-modal-text">
            Jawaban anda akan segera direkam setelah anda klik tombol OK di
            bawah.
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <PButton onClick={toggle}>OK, Submit</PButton>
      </ModalFooter>
    </Modal>
  );
};

export default TimeUpModal;
