import React, { useEffect } from 'react';
import './App.css';
import './buttons.css';
import './forms.css';
import './fonts.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import LoginPage from './pages/LoginPage';
import Header from './components/Header';
import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/NotFoundPage';
import TestPage from './pages/TestPage';
import { keepLogin } from './redux/modules/auth';
import ResultPage from './pages/resultPage/index';
import AdminPage from './pages/AdminPage';
import RegisterPage from './pages/RegisterPage';

const App = () => {
  const dispatch = useDispatch();

  const { tokenChecked, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(keepLogin());
  }, [dispatch]);

  const renderRoutes = () => {
    if (!tokenChecked) {
      return (
        <div className="row h-100">
          <div className="col h-100">
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <Loader type="ThreeDots" color="#FFF" height={100} width={100} />
            </div>
          </div>
        </div>
      );
    }
    return (
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/home" component={LandingPage} />
        <Route path="/test/:id" component={TestPage} />
        {/* <Route path="*" exact component={NotFoundPage} /> */}
        {/* Checks for admin privileges */}
        {user.isAdmin && (
          <>
            <Route exact path="/admin" component={AdminPage} />
            <Route path="/admin/result" component={ResultPage} />
            <Route path="/admin/register-user" component={RegisterPage} />
          </>
        )}
        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    );
  };

  return (
    <div className="App">
      <div
        style={{ minHeight: '100vh', backgroundColor: 'rgb(232, 232, 232)' }}
      >
        <Header />
        {renderRoutes()}
      </div>
    </div>
  );
};

export default App;
