import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Table, Row, Col } from 'reactstrap';
import querystring from 'query-string';
import {
  fetchAllResults,
  findTestResultByUserId,
  resetFilteredData,
  findTestResultByName,
  findTestResultByEmail,
  findTestResultByGroupIdOnly,
} from '../../redux/modules/result';
import { fetchAllGroups } from '../../redux/modules/group';

import SidebarComponent from './SidebarComponent';

const ResultPage = (props) => {
  const dispatch = useDispatch();

  const completeData = useSelector((state) => state.result.completeData);
  const filteredData = useSelector((state) => state.result.filteredData);
  const groups = useSelector((state) => state.group.groups);

  const [
    openUserFullnameDropdownButton,
    setOpenUserFullnameDropdownButton,
  ] = useState(false);
  const [openEmailDropdownButton, setOpenEmailDropdownButton] = useState(false);
  const [openGroupDropdownButton, setOpenGroupDropdownButton] = useState(false);

  const paramsObjectFromAddressBar = querystring.parse(props.location.search);
  const [inputFullname, setInputFullname] = useState(
    paramsObjectFromAddressBar.inputBoxField === 'fullName'
      ? paramsObjectFromAddressBar.value
      : '',
  );
  const [inputEmail, setInputEmail] = useState(
    paramsObjectFromAddressBar.inputBoxField === 'email'
      ? paramsObjectFromAddressBar.value
      : '',
  );
  const [selectedUserId, setSelectedUserId] = useState(
    paramsObjectFromAddressBar.userId
      ? parseInt(paramsObjectFromAddressBar.userId)
      : null,
  );
  const [selectedGroupId, setSelectedGroupId] = useState(
    paramsObjectFromAddressBar.filterByGroupId
      ? parseInt(paramsObjectFromAddressBar.groupId)
      : null,
  );

  useEffect(() => {
    dispatch(fetchAllResults());
    dispatch(fetchAllGroups());
  }, [dispatch]);

  const onSearchData = () => {
    switch (true) {
      case selectedUserId !== null: {
        // when user selecting name-dropdownOption, or selecting email-dropdownOption, this will be executed
        let paramsObject = { userId: selectedUserId };
        if (selectedGroupId !== null) {
          paramsObject = { ...paramsObject, groupId: selectedGroupId };
        }
        return dispatch(findTestResultByUserId(paramsObject));
      }
      case inputFullname !== '': {
        // when user typing in name-searchBox, this will be executed
        let paramsObject = { keyword: inputFullname };
        if (selectedGroupId !== null) {
          paramsObject = { ...paramsObject, groupId: selectedGroupId };
        }
        return dispatch(findTestResultByName(paramsObject));
      }
      case inputEmail !== '': {
        // when user typing in email-searchBox, this will be executed
        let paramsObject = { keyword: inputEmail };
        if (selectedGroupId !== null) {
          paramsObject = { ...paramsObject, groupId: selectedGroupId };
        }
        return dispatch(findTestResultByEmail(paramsObject));
      }
      case selectedGroupId !== null: {
        // when user selecting group-dropdownOption, this will be executed
        return dispatch(findTestResultByGroupIdOnly(selectedGroupId));
      }
      default: {
        // when user :
        // - clearing name-searchBox or email-searchBox
        // - clearing name-dropdownOption or email-dropdownOption
        // - group-dropdownOption
        // (by clicking X button)
        // this will be executed
        return dispatch(resetFilteredData());
      }
    }
  };

  const onResetFilter = () => {
    dispatch(resetFilteredData());
    // clear all state
    setOpenEmailDropdownButton(false);
    setOpenGroupDropdownButton(false);
    setInputFullname('');
    setInputEmail('');
    setSelectedUserId(null);
    setSelectedGroupId(null);
  };

  const renderTable = () => {
    const dataToBeRendered =
      filteredData.length === 0 ? completeData : filteredData;
    return dataToBeRendered.map((result, index) => {
      return (
        <tr key={result.id}>
          <td>{index + 1}</td>
          <td>{result.fullName}</td>
          <td>{result.email}</td>
          <td>{result.groupName}</td>
          <td>{result.title}</td>
          <td>
            {result.correctAnswers}/{result.totalQuestions} :
          </td>
          <td>{result.result}</td>
        </tr>
      );
    });
  };

  const listUserFullnameForDropdownButton = completeData
    // remove duplicates
    .filter(
      (item, index, self) =>
        self.findIndex((t) => t.userId === item.userId) === index,
    )
    .map((item) => {
      const { userId, fullName } = item;
      return { userId, optionToBeDisplayed: fullName };
    })
    .sort((a, b) => (a.optionToBeDisplayed > b.optionToBeDisplayed ? 1 : -1));

  const listlUserEmailForDropdownButton = completeData
    // remove duplicates
    .filter(
      (thing, index, self) =>
        self.findIndex((t) => t.userId === thing.userId) === index,
    )
    .map((item) => {
      const { userId, email } = item;
      return { userId, optionToBeDisplayed: email };
    })
    .sort((a, b) => (a.optionToBeDisplayed > b.optionToBeDisplayed ? 1 : -1));

  const listGroupForDropdownButton = groups
    .map((item) => item)
    .sort((a, b) => (a.groupName > b.groupName ? 1 : -1));

  const pushToAddressBar = (objectToBePushed) => {
    if (objectToBePushed) {
      props.history.push(
        `/admin/result?${querystring.stringify(objectToBePushed)}`,
      );
    } else {
      props.history.push(`/admin/result`);
    }
  };

  const propsForSidebarComponent = {
    listlUserEmailForDropdownButton,
    openEmailDropdownButton,
    setOpenEmailDropdownButton,
    inputEmail,
    setInputEmail,
    setInputFullname,
    selectedUserId,
    setSelectedUserId,
    selectedGroupId,
    pushToAddressBar,
    listUserFullnameForDropdownButton,
    openUserFullnameDropdownButton,
    setOpenUserFullnameDropdownButton,
    inputFullname,
    listGroupForDropdownButton,
    openGroupDropdownButton,
    setOpenGroupDropdownButton,
    setSelectedGroupId,
    onSearchData,
    onResetFilter,
  };

  return (
    <div>
      <SidebarComponent propsForSidebarComponent={propsForSidebarComponent} />
      <Container className="pt-5" fluid>
        <Row>
          <Col lg={4} />
          <Col lg={8}>
            <Table className="resultPage-dataTable" size="lg">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Group</th>
                  <th>Test</th>
                  <th colSpan={2}>Result</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResultPage;
