import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Input,
  Label,
} from 'reactstrap';
import { API_URL_1 } from '../supports/api-url/apiurl';
import { encrypt } from '../supports/helpers/encryption';
import { appKey } from '../supports/config';
import { fetchAllGroups } from '../redux/modules/group';

const trimSpace = (str) => {
  return str.replace(/\s+/g, '');
};

const RegisterPage = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groupId, setGroupId] = useState('');

  const groups = useSelector((state) => state.group.groups);

  useEffect(() => {
    dispatch(fetchAllGroups());
  }, [dispatch]);

  const GroupOptions = () => {
    return groups.map(({ id, groupName }) => (
      <option key={id} value={id}>
        {groupName}
      </option>
    ));
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const encryptedPassword = encrypt(appKey, password); // encrypted password

    const token = localStorage.getItem('token');
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const body = {
        // trim email & username
        email: trimSpace(email),
        username: trimSpace(username),
        // password is now encryptedPassword
        password: encryptedPassword,
        firstName,
        lastName,
        groupId,
      };
      const registerResponse = await axios.post(
        `${API_URL_1}/auth/create`,
        body,
        options,
      );

      const { message } = registerResponse.data;

      window.alert(message);

      setEmail('');
      setUsername('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setGroupId('');
    } catch (err) {
      console.log(err);
      window.alert(`Terjadi kesalahan pada system. ${err}`);
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center">
        <div className="mt-5" style={{ width: '50%', height: '500px' }}>
          <Card className="drop-shadow">
            <CardBody>
              <div
                style={{ height: '700px' }}
                className="d-flex justify-content-center align-items-center"
              >
                <div>
                  <CardTitle>
                    <strong style={{ fontSize: '30px' }}>Register User</strong>
                  </CardTitle>
                  <form onSubmit={handleRegister}>
                    <Label className="mb-0 mt-2">First Name</Label>
                    <Input
                      value={firstName}
                      className="my-2"
                      placeholder="First Name"
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />

                    <Label className="mb-0 mt-2">Last Name</Label>
                    <Input
                      value={lastName}
                      className="my-2"
                      placeholder="Last Name"
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />

                    <Label className="mb-0 mt-2">Email</Label>
                    <Input
                      value={email}
                      className="my-2"
                      placeholder="Email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <Label className="mb-0 mt-2">Username</Label>
                    <Input
                      value={username}
                      className="my-2"
                      placeholder="Username"
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />

                    <Label className="mb-0 mt-2">Password</Label>
                    <Input
                      value={password}
                      className="my-2"
                      placeholder="Password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />

                    <Label className="mb-0 mt-2">CT Group</Label>
                    <Input
                      type="select"
                      className="my-2"
                      value={groupId}
                      onChange={(e) => setGroupId(e.target.value)}
                      required
                    >
                      <option value="">Select CT Group</option>
                      <GroupOptions />
                    </Input>
                    <Button className="my-4" type="submit">
                      REGISTER USER
                    </Button>
                  </form>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </Container>
  );
};

export default RegisterPage;
