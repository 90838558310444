import React from 'react';
import { Alert } from 'reactstrap';

const PInput = ({
  id,
  type = 'text',
  value,
  onChange,
  className,
  style,
  placeholder,
  label,
  required = false,
  max,
  min,
  step,
  valid = true,
  invalidColor = 'red',
  invalidMessage,
  labelClassName = '',
  containerClassName = '',
  Checked = false,
  ...otherProps
}) => {
  let labelCSS = '';
  if (type === 'checkbox' && label) {
    labelCSS = 'pwdk-checkbox-container';
  }
  const CheckLabel = () => {
    if (type === 'checkbox' && label) {
      return <label className={labelClassName}>{label}</label>;
    }
    return null;
  };

  const RenderInvalidMessage = () => {
    if (!valid) {
      // console.log('amsjadsd');
      return (
        <Alert
          className={`pwdk-label pwdk-label-${invalidColor} mt-2 mb-0 w-100`}
          style={{ border: 'none' }}
        >
          {invalidMessage}
        </Alert>
      );
    }

    return null;
  };

  return (
    <div className={`${labelCSS} ${containerClassName}`}>
      <input
        id={id}
        type={type}
        className={`pwdk-form ${className}`}
        value={value}
        onChange={onChange}
        style={{ ...style }}
        placeholder={placeholder}
        required={required}
        max={max}
        min={min}
        step={step}
        checked={Checked}
        {...otherProps}
      />

      <RenderInvalidMessage />

      <CheckLabel />
    </div>
  );
};

export default PInput;
