import React from 'react';
import {
  InputGroup,
  Input,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  Button,
} from 'reactstrap';

const SearchAndSelectComponent = (props) => {
  const {
    dropdownOptionsData,
    isOpen,
    toggleDropDown,
    inputBoxField,
    inputBoxValue,
    setInputBoxValue,
    clearAnotherInputBox,
    clearAllInputBox,
    selectedUserId,
    setSelectedUserId,
    selectedGroupId,
    pushToAddressBar,
  } = props;

  const paramsObjectForSelectGroupId = {
    filterByGroupId: true,
    groupId: selectedGroupId,
  };

  const renderDropdownOptions = () => {
    const dropdownOptionsArray = [];
    const loopFrequency = dropdownOptionsData.length;
    for (let i = 0; i < loopFrequency; i += 1) {
      const { userId, optionToBeDisplayed } = dropdownOptionsData[i];
      dropdownOptionsArray.push(
        <DropdownItem
          key={userId.toString()}
          disabled={selectedUserId === userId}
          onClick={() => {
            setSelectedUserId(userId);
            clearAllInputBox();

            // set paramsObject for selected userId in address bar
            const paramsObjectForSelectUserId = {
              userId,
            };
            if (selectedGroupId !== null) {
              pushToAddressBar({
                ...paramsObjectForSelectUserId,
                ...paramsObjectForSelectGroupId,
              });
            } else {
              pushToAddressBar(paramsObjectForSelectUserId);
            }
          }}
        >
          {optionToBeDisplayed}
        </DropdownItem>,
      );
    }
    return dropdownOptionsArray;
  };

  return (
    <InputGroup>
      <InputGroupButtonDropdown
        addonType="prepend"
        isOpen={isOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret>
          {selectedUserId !== null && dropdownOptionsData.length > 0
            ? dropdownOptionsData.filter(
                (item) => item.userId === selectedUserId,
              )[0].optionToBeDisplayed
            : 'Select'}
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: (data) => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: '300px',
                  },
                };
              },
            },
          }}
        >
          {renderDropdownOptions()}
        </DropdownMenu>
      </InputGroupButtonDropdown>
      <Input
        placeholder="type here"
        value={inputBoxValue}
        onChange={(e) => {
          setInputBoxValue(e.target.value);
          clearAnotherInputBox();
          setSelectedUserId(null);

          // set paramsObject for inputed name/email value in address bar
          const paramsObjectForInputValue = {
            inputBoxField,
            value: e.target.value,
          };
          if (selectedGroupId !== null) {
            pushToAddressBar({
              ...paramsObjectForInputValue,
              ...paramsObjectForSelectGroupId,
            });
          } else {
            pushToAddressBar(paramsObjectForInputValue);
          }
        }}
      />
      <InputGroupAddon addonType="append">
        <Button
          disabled={!selectedUserId && inputBoxValue === ''}
          onClick={() => {
            setSelectedUserId(null);
            clearAllInputBox();

            // clear paramsObject for selected userId or inputed name/email value in address bar
            // (only push paramsObject for selected groupId or inputed groupName )
            if (selectedGroupId !== null) {
              pushToAddressBar(paramsObjectForSelectGroupId);
            } else {
              pushToAddressBar();
            }
          }}
        >
          X
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default SearchAndSelectComponent;
