import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const NotFoundPage = () => {
  const { user } = useSelector((state) => state.auth);

  if (user.id) {
    return (
      <div>
        <h1>404 Not Found</h1>
      </div>
    );
  }

  return <Redirect to="/" />;
};

export default NotFoundPage;
