const crypto = require('crypto');
const { algorithm, iv } = require('../config');

module.exports = {
  encrypt(key, data) {
    const cipher = crypto.createCipheriv(algorithm, key, iv);
    let crypted = cipher.update(data, 'utf-8', 'hex');
    crypted += cipher.final('hex');

    return crypted;
  },
};
