import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import PButton from '../components/PButton';
import PInput from '../components/PInput';
import { signIn } from '../redux/modules/auth';
import Disclaimer from '../components/Disclaimer';

const LoginPage = () => {
  const { user, loading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const handleLogin = async (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(signIn({ username, password }));
  };

  const onEnter = (e) => {
    if (e.which === 13) {
      handleLogin();
    }
  };

  if (user.isAdmin) {
    return <Redirect to="/admin" />;
  }

  if (!user.isAdmin && user.id) {
    return <Redirect to="/home" />;
  }

  const SignInButton = ({ onClick, loggingIn }) => {
    if (loggingIn) {
      return (
        <PButton className="w-100 mb-4 d-flex align-items-center justify-content-center">
          <Spinner color="light" style={{ width: '20px', height: '20px' }} />
        </PButton>
      );
    }
    return (
      <PButton className="w-100 mb-4 f--16 font-weight-bold" onClick={onClick}>
        Login
      </PButton>
    );
  };

  const RenderVisibilityPassword = () => {
    if (visibilityPassword) {
      return (
        <div
          role="button"
          onClick={() => setVisibilityPassword(false)}
          tabIndex={0}
          onKeyDown={() => {}}
          style={{ cursor: 'pointer', width: '50px' }}
          className="pwdk-icon-adjustment icon-ic_person_outline d-block"
        >
          Hide
        </div>
      );
    }

    return (
      <div
        role="button"
        onClick={() => setVisibilityPassword(true)}
        tabIndex={0}
        onKeyDown={() => {}}
        style={{ cursor: 'pointer', width: '50px' }}
        className="pwdk-icon-adjustment icon-ic_person_outline d-block"
      >
        Show
      </div>
    );
  };

  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Card
          className="drop-shadow my-5"
          style={{ maxWidth: '555px', width: '100%' }}
        >
          <CardBody>
            <div className="d-flex justify-content-start">
              <h3 className="mb-4 f--weight-900 f--rich-black">Login</h3>
            </div>
            <div>
              <form onSubmit={handleLogin}>
                <PInput
                  id="emailinput"
                  className="mb-3"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  placeholder="Username"
                  required
                  invalid="false"
                  onKeyPress={onEnter}
                />

                <div className="pwdk-form-icon-container pwdk-mb-2 pwdk-sm-mb-1 pwdk-mr-3">
                  <PInput
                    id="passwordinput"
                    type={visibilityPassword ? 'text' : 'password'}
                    className="mb-3"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Password"
                    required
                    onKeyPress={onEnter}
                    style={{
                      paddingRight: '15%',
                    }}
                  />
                  <RenderVisibilityPassword />
                </div>

                <SignInButton onClick={handleLogin} loggingIn={loading} />
              </form>
            </div>
          </CardBody>
        </Card>
        <Disclaimer />
      </div>
    </div>
  );
};

export default LoginPage;
