module.exports = {
  randomizeArray(array) {
    const listData = array;
    for (let i = listData.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      [listData[i], listData[j]] = [listData[j], listData[i]];
    }
    return listData;
  },
};
