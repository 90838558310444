import React from 'react';
import {
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  Button,
} from 'reactstrap';

const SearchAndSelectComponentForGroups = (props) => {
  const {
    dropdownOptionsData,
    isOpen,
    toggleDropDown,
    selectedGroupId,
    setSelectedGroupId,
    selectedUserId,
    inputFullname,
    inputEmail,
    pushToAddressBar,
  } = props;

  const paramsObjectForSelectUserId = {
    userId: selectedUserId,
  };
  const paramsObjectForInputName = {
    inputBoxField: 'fullName',
    value: inputFullname,
  };
  const paramsObjectForInputEmail = {
    inputBoxField: 'email',
    value: inputEmail,
  };

  const renderDropdownOptions = () => {
    const dropdownOptionsArray = [];
    const loopFrequency = dropdownOptionsData.length;
    for (let i = 0; i < loopFrequency; i += 1) {
      const { id, groupName } = dropdownOptionsData[i];
      dropdownOptionsArray.push(
        <DropdownItem
          key={id.toString()}
          disabled={selectedGroupId === id}
          onClick={() => {
            setSelectedGroupId(id);

            // set paramsObject for selected groupId in address bar
            const paramsObjectForSelectGroupId = {
              filterByGroupId: true,
              groupId: id,
            };
            if (selectedUserId !== null) {
              pushToAddressBar({
                ...paramsObjectForSelectGroupId,
                ...paramsObjectForSelectUserId,
              });
            } else if (inputFullname !== '') {
              pushToAddressBar({
                ...paramsObjectForSelectGroupId,
                ...paramsObjectForInputName,
              });
            } else if (inputEmail !== '') {
              pushToAddressBar({
                ...paramsObjectForSelectGroupId,
                ...paramsObjectForInputEmail,
              });
            } else {
              pushToAddressBar(paramsObjectForSelectGroupId);
            }
          }}
        >
          {groupName}
        </DropdownItem>,
      );
    }
    return dropdownOptionsArray;
  };

  return (
    <InputGroup>
      <InputGroupButtonDropdown
        addonType="prepend"
        isOpen={isOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret>
          {selectedGroupId !== null && dropdownOptionsData.length > 0
            ? dropdownOptionsData.filter(
                (item) => item.id === selectedGroupId,
              )[0].groupName
            : 'Select Group'}
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: (data) => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: '300px',
                  },
                };
              },
            },
          }}
        >
          {renderDropdownOptions()}
        </DropdownMenu>
      </InputGroupButtonDropdown>
      <InputGroupAddon addonType="append">
        <Button
          disabled={!selectedGroupId}
          onClick={() => {
            setSelectedGroupId(null);

            // clear paramsObject for selected groupId or inputed groupName in address bar
            // (only push paramsObject for selected userId or inputed name/email value )
            if (selectedUserId !== null) {
              pushToAddressBar(paramsObjectForSelectUserId);
            } else if (inputFullname !== '') {
              pushToAddressBar(paramsObjectForInputName);
            } else if (inputEmail !== '') {
              pushToAddressBar(paramsObjectForInputEmail);
            } else {
              pushToAddressBar();
            }
          }}
        >
          X
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default SearchAndSelectComponentForGroups;
