import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Container, Progress } from 'reactstrap';
import { fetchAllTestHeader, resetTestDataState } from '../redux/modules/test';

import quizImage from '../components/image/quiz_image.svg';
import PButton from '../components/PButton';
import TncModal from '../components/TncModal';

const LandingPage = () => {
  const dispatch = useDispatch();

  const dataTestHeader = useSelector((state) => state.test.headerData);
  const user = useSelector((state) => state.auth.user);

  const [openTncModal, setOpenTncModal] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(0);

  useEffect(() => {
    dispatch(fetchAllTestHeader());
  }, [dispatch]);

  // NOTE: reset selected test on landing page
  useEffect(() => {
    dispatch(resetTestDataState());
  }, [dispatch]);

  const userId = useSelector((state) => state.auth.user.id);

  const findCompletedTestId = (id) => {
    return user.completion.find((test) => test.testId === id);
  };

  const RenderButton = ({ testIsComplete, testId, zeroProgress }) => {
    if (zeroProgress) {
      return (
        <PButton
          className="w-100 mb-2 f--16 font-weight-bold"
          style={{ outline: 'none' }}
          onClick={() => {
            setOpenTncModal(true);
            setSelectedTestId(testId);
          }}
        >
          Start Quiz
        </PButton>
      );
    }
    return (
      <Link to={`/test/${testId}`} style={{ width: '100%' }}>
        <PButton
          disabled={testIsComplete}
          className="w-100 mb-2 f--16 font-weight-bold"
          style={{ outline: 'none' }}
        >
          Start Quiz
        </PButton>
      </Link>
    );
  };

  const renderCards = () => {
    // filter shown dataTestHeaders by user.group.tests
    const filteredDataTestHeader = dataTestHeader.filter((testHeader) => {
      const foundTest = user.group?.tests?.find((groupTest) => {
        return groupTest.id === testHeader.id;
      });
      return foundTest;
    });

    let shownHeaderCards = filteredDataTestHeader;

    // if admin, show all
    if (user.isAdmin) {
      shownHeaderCards = dataTestHeader;
    }

    return shownHeaderCards.map(
      ({ id, title, description, totalQuestions }) => {
        // from local storage
        const countUserAnswer = localStorage.getItem(
          `countUserAnswerQuestionTest${id}_userId${userId}`,
        );

        // if test is completed, use totalQuestions for counting to get 100%
        const count = findCompletedTestId(id)
          ? totalQuestions
          : countUserAnswer;

        const progress = Math.round((count / totalQuestions) * 100);

        return (
          <div key={id} className="col-12" style={{ marginBottom: '2.2em' }}>
            <Card style={{ borderRadius: '6px' }}>
              <CardBody>
                <div className="d-flex">
                  <div className="col-3">
                    <img src={quizImage} alt="quiz" />
                  </div>
                  <div className="col-4">
                    <div className="d-flex flex-column">
                      <div className="quiz-category">Quiz</div>
                      <div className="quiz-title">{title}</div>
                      <div className="quiz-description">{description}</div>
                      <div style={{ textAlign: 'left' }}>
                        <div className="d-flex flex-row class-content">
                          <div
                            className="class-progress"
                            style={{ width: '17em' }}
                          >
                            <div className="d-flex flex-row">
                              <div className="f-md-body-small f--dark-gray font-weight-bold">
                                PROGRESS QUIZ
                              </div>
                              <div className="ml-auto f-md-body-small">
                                {progress}/ 100 %
                              </div>
                            </div>
                            <div className="mt-1">
                              <Progress
                                value={Math.round(
                                  (count / totalQuestions) * 100,
                                )}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="d-flex align-items-end h-100">
                      <RenderButton
                        testIsComplete={findCompletedTestId(id)}
                        testId={id}
                        zeroProgress={progress <= 0}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      },
    );
  };

  if (!userId) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Container>
        <div className="welcome-text">
          Welcome, {user.firstName} {user.lastName}!
          <div
            // border div
            style={{
              borderBottom: '1px solid rgb(206 204 204)',
              marginTop: '0.5em',
            }}
          />
        </div>
        <div className="mt-4 d-flex flex-wrap">{renderCards()}</div>
        <TncModal
          isOpen={openTncModal}
          toggle={() => setOpenTncModal(!openTncModal)}
          testId={selectedTestId}
        />
      </Container>
    </div>
  );
};

export default LandingPage;
