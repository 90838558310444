import React from 'react';
import { Input, Button } from 'reactstrap';
import { API_URL_1 } from '../supports/api-url/apiurl';

const TestItem = (props) => {
  const {
    item,
    index: indexQuestion,
    handleChange,
    inputs,
    handleStringChange,
    componentSubmit,
    componentPagination,
  } = props;

  const radioInput = (test_item) => {
    return test_item.test_item_choices.map((choice) => {
      return (
        <Button
          key={choice.choiceString}
          className={`answer-component mb-2 ${
            inputs[`answer${test_item.id}`]?.user_answer === choice.choice
              ? 'selected-answer'
              : ''
          }`}
          name={`answer${test_item.id}`}
          onClick={() => handleChange(choice.choice, test_item.id)}
          onKeyDown={() => {}}
          value={choice.choice}
        >
          {choice.choiceString}
        </Button>
      );
    });
  };

  const textInput = (test_item) => {
    const range = [...Array(test_item.totalInputs).keys()];
    return range.map((key) => {
      // map how much input needed for current question
      return (
        <div key={key} className="d-flex flex-column">
          <Input
            type={test_item.inputType || 'text'}
            className={test_item.inputType !== 'textarea' ? 'text-center' : ''}
            name={`answer${test_item.id}`}
            onChange={(e) => handleStringChange(e, test_item.id, key)}
            // value for input text example inputs.answer1.useranswer.input1
            value={
              inputs[`answer${test_item.id}`]?.user_answer[`input${key}`] || ''
            }
            placeholder="Your Answer Here"
          />
          <p className="mb-0 mx-2" style={{ fontSize: '20px' }}>
            {key + 1 === range.length ? null : '-'}{' '}
          </p>
        </div>
      );
    });
  };

  return (
    <div>
      <h5>Problem {indexQuestion + 1}</h5>
      {/* checks for instruction (string) 
        or use picture for the problem set */}
      <div className="test-problem-container px-3">
        {item?.instructions && <h5>{item?.instructions}</h5>}
        {item?.picture && (
          <div className="d-flex justify-content-center">
            <div style={{ width: '90%' }}>
              <img
                style={{ minWidth: '500px', maxWidth: '90%' }}
                src={`${API_URL_1}/${item.picture}`}
                alt="test-header"
              />
            </div>
          </div>
        )}
      </div>
      {/* maps the question (can be more 
        than one question per problem set) */}
      {item?.test_items.map((test_item, id) => {
        return (
          <div key={test_item.id} className="row m-0">
            {/* Content Kanan */}
            <div className="col-6 p-0 border-right">
              <div className="question-style">
                <p>{test_item.question}</p>
              </div>
            </div>

            {/* Content Kiri */}
            <div className="col-6 p-0">
              <div className="answer-style">
                {/* checks if the question is a multiple choice
              or not */}
                {test_item.isMultipleChoice ? (
                  // returns multiple choices from database
                  radioInput(test_item)
                ) : (
                  // returns input type=text based on totalInputs)
                  <div className="d-flex flex-column justify-content-center">
                    {textInput(test_item)}
                  </div>
                )}
                {id === Object.keys(item?.test_items).length - 1 ? (
                  <div className="test-button-container">
                    {componentSubmit}
                    <div className="test-button-pagination mt-5 text-right">
                      {componentPagination}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TestItem;
