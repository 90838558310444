// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { API_URL_1 } from '../../supports/api-url/apiurl';

const initialState = {
  loading: false,
  groups: [],
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'group',
  initialState,
  reducers: {
    fetchGrouptStart: (state) => {
      state.loading = true;
    },
    setGroupData: (state, action) => {
      state.groups = action.payload;
    },
    fetchGroupSuccess: (state) => {
      state.loading = false;
    },
    fetchGroupFailed: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchGrouptStart,
  setGroupData,
  fetchGroupSuccess,
  fetchGroupFailed,
} = actions;

export const fetchAllGroups = () => {
  return async (dispatch) => {
    dispatch(fetchGrouptStart());
    try {
      const result = await Axios.get(`${API_URL_1}/group/all`);
      dispatch(setGroupData(result.data.data));
      dispatch(fetchGroupSuccess());
    } catch (err) {
      dispatch(fetchGroupFailed());
      window.alert(err);
    }
  };
};
