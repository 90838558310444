import React, {
  // useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import Axios from 'axios';
import moment from 'moment';
// import _ from 'lodash';
import Loader from 'react-loader-spinner';
import TestItem from '../components/TestItem';
import PButton from '../components/PButton';
import SuccessModal from '../components/SuccessModal';
import { fetchTestById, assignCountUserAnswer } from '../redux/modules/test';
import { API_URL_1 } from '../supports/api-url/apiurl';
import { setCompletion } from '../redux/modules/auth';
import TimeUpModal from '../components/TimeUpModal';
import SkipModal from '../components/SkipModal';

const TestPage = () => {
  const { id } = useParams();
  const testId = id;

  const dispatch = useDispatch();

  const data = useSelector((state) => state.test.data);
  const userId = useSelector((state) => state.auth.user.id);
  const { group, isAdmin, completion } = useSelector(
    (state) => state.auth.user,
  );
  const loading = useSelector((state) => state.test.loading);

  const [inputs, setInputs] = useState(
    JSON.parse(localStorage.getItem(`inputs${testId}_userId${userId}`)) || {},
  );
  const [questionNumber, setQuestionNumber] = useState(
    Number(localStorage.getItem(`questionNumberTest${testId}_userId${userId}`)),
  );
  const [countUserAnswer, setCountUserAnswer] = useState(
    Number(
      localStorage.getItem(
        `countUserAnswerQuestionTest${testId}_userId${userId}`,
      ) || 0,
    ),
  );
  const [submitDone, setSubmitDone] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [timeUpModal, setTimeUpModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableAccess, setDisableAccess] = useState(false);
  const [accessChecked, setAccessChecked] = useState(false);
  const [skipModal, setSkipModal] = useState(false);

  // checking test access by group.tests
  useEffect(() => {
    const foundTest = group?.tests?.find((groupTest) => {
      return groupTest.id === Number(testId);
    });

    if (!foundTest && !isAdmin) {
      setDisableAccess(true);
    }
    setAccessChecked(true);
  }, [group, testId, isAdmin]);

  useEffect(() => {
    const findCompletedTestId = completion?.find(
      (test) => test.testId === Number(testId),
    );

    if (findCompletedTestId && !isAdmin) {
      setSubmitDone(true);
    }
    // sengaja dependencies kosong biar jalan bener2 pas onMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListUserAnswerFromInputs = (listUserAnswer) => {
    const userAnswers = [];
    Object.keys(listUserAnswer).forEach((key) => {
      if (key && key !== 'undefined') {
        // condition for string answers
        if (typeof listUserAnswer[key].user_answer === 'object') {
          // converts user_answer to an array then join with '-'
          const keys = Object.keys(listUserAnswer[key].user_answer);
          // sort them keys
          keys.sort();
          const toJoin = keys.map((idx) => {
            return listUserAnswer[key].user_answer[idx];
          });
          return userAnswers.push({
            test_itemId: listUserAnswer[key].test_itemId,
            user_answer: toJoin.join('-'),
          });
        }
        return userAnswers.push(listUserAnswer[key]);
      }
      return null;
    });
    return userAnswers;
  };

  const getListIndexAnsweredByUser = (listUserAnswer, listQuestion) => {
    return listUserAnswer
      .map((answerItem) => {
        return listQuestion.findIndex(
          (questionItem) => questionItem.id === answerItem.test_itemId,
        );
      })
      .sort((firstItem, lastItem) => firstItem - lastItem);
  };

  // saves input progress using debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceSave = useCallback(
  //   _.debounce((newInputs) => {
  //     localStorage.setItem(
  //       `inputs${testId}_userId${userId}`,
  //       JSON.stringify(newInputs),
  //     );
  //   }, 500),
  //   [],
  // );

  const handleChange = (choice, test_itemId) => {
    // useranswer radio button is just a normal string 'foo'

    if (test_itemId && choice) {
      const newInputs = {
        ...inputs,
        [`answer${test_itemId}`]: { test_itemId, user_answer: choice },
      };
      const listQuestion = data.test_item_headers.map(
        (questionItem) => questionItem.test_items[0],
      );

      const listUserAnswer = getListUserAnswerFromInputs(newInputs);

      const countAnswer = getListIndexAnsweredByUser(
        listUserAnswer,
        listQuestion,
      ).length;
      localStorage.setItem(
        `countUserAnswerQuestionTest${testId}_userId${userId}`,
        countAnswer,
      );
      setInputs(newInputs);
      setCountUserAnswer(countAnswer);
      localStorage.setItem(
        `inputs${testId}_userId${userId}`,
        JSON.stringify(newInputs),
      );
    }
    // debounceSave();
  };

  const handleStringChange = (e, test_itemId, key) => {
    // user_answer for string example
    // user_anser: {
    //   input0: 'foo',
    //   input1: 'bar'
    // }

    if (e?.target?.name) {
      const newInputs = {
        ...inputs,
      };

      if (e.target.value) {
        newInputs[e.target.name] = {
          test_itemId,
          user_answer: {
            ...inputs[e.target.name]?.user_answer,
            [`input${key}`]: e.target.value,
          },
        };
        // if input value is deleted/become empty but the question has multiple inputs
      } else if (Object.keys(inputs[e.target.name]?.user_answer).length > 1) {
        delete newInputs[e.target.name]?.user_answer[`input${key}`];
      } else {
        // if input value is deleted/become empty but the question has only single input
        delete newInputs[e.target.name];
      }

      const listQuestion = data.test_item_headers.map(
        (questionItem) => questionItem.test_items[0],
      );

      const listUserAnswer = getListUserAnswerFromInputs(newInputs);

      const countAnswer = getListIndexAnsweredByUser(
        listUserAnswer,
        listQuestion,
      ).length;

      localStorage.setItem(
        `countUserAnswerQuestionTest${testId}_userId${userId}`,
        countAnswer,
      );

      setInputs(newInputs);
      setCountUserAnswer(countAnswer);
      localStorage.setItem(
        `inputs${testId}_userId${userId}`,
        JSON.stringify(newInputs),
      );
      // debounceSave(newInputs);
    }
  };

  useEffect(() => {
    if (!disableAccess && accessChecked) {
      // fetchTest if finished checking access & access is not disabled
      dispatch(fetchTestById(testId, userId));
    }
  }, [dispatch, testId, disableAccess, accessChecked, userId]);

  useEffect(() => {
    if (countUserAnswer) {
      const countAnswerByTestId = localStorage.getItem(
        `countUserAnswerQuestionTest${testId}_userId${userId}`,
      );

      dispatch(assignCountUserAnswer(countAnswerByTestId));
    } else {
      dispatch(assignCountUserAnswer(0));
    }
  }, [dispatch, countUserAnswer, testId, userId]);

  // for cleaning up timer if answer is submitted
  useEffect(() => {
    if (submitDone) {
      localStorage.removeItem(`shouldEndAtTest${testId}_userId${userId}`);
      return null;
    }
    return null;
  }, [submitDone, testId, userId]);

  const submitAnswers = async (isTimeUp) => {
    // e.preventDefault();

    setLoadingButton(true);

    const listUserAnswer = getListUserAnswerFromInputs(inputs);

    // checking listUserAnswer match or not with total question
    if (
      (data.totalQuestions <= countUserAnswer || isTimeUp) &&
      !submittedOnce
    ) {
      try {
        const scheduleTo = localStorage.getItem(
          `shouldEndAtTest${testId}_userId${userId}`,
        );
        // time is in ms (miliseconds)
        const completionTime =
          data.duration -
          Number(moment.duration(moment(scheduleTo).diff(moment())));

        if (data.isEssay) {
          // Kalo test header isEssay = true
          const result = await Axios.post(`${API_URL_1}/test/essay/${testId}`, {
            answers: listUserAnswer,
            userId,
            totalQuestions: data.totalQuestions,
            completionTime,
          });
          dispatch(setCompletion(result.data.data.completion));
        } else {
          const result = await Axios.post(`${API_URL_1}/test/${testId}`, {
            answers: listUserAnswer,
            userId,
            totalQuestions: data.totalQuestions,
            completionTime,
          });
          dispatch(setCompletion(result.data.data.completion));
        }

        setSubmittedOnce(true);

        localStorage.removeItem(`inputs${testId}_userId${userId}`);
        localStorage.removeItem(`questionNumberTest${testId}_userId${userId}`);
        localStorage.removeItem(
          `countUserAnswerQuestionTest${testId}_userId${userId}`,
        );
        // assign count answer to 0 after submit all answer in test page
        dispatch(assignCountUserAnswer(0));
        setModalSuccess(true);
        setLoadingButton(false);
      } catch (err) {
        setLoadingButton(false);
        window.alert(err);
      }
    }
  };

  // this toggle submits answer
  const toggleTimeUpModal = () => {
    submitAnswers(true);
  };

  const saveQuestionPosition = (indexQuestion) => {
    window.scrollTo(0, 0);
    setQuestionNumber(indexQuestion);
    localStorage.setItem(
      `questionNumberTest${testId}_userId${userId}`,
      indexQuestion,
    );
  };

  const RenderCountDown = () => {
    // countdown timer
    const scheduleTo = localStorage.getItem(
      `shouldEndAtTest${testId}_userId${userId}`,
    );

    const [miliseconds, setMiliseconds] = useState(
      Number(moment.duration(moment(scheduleTo).diff(moment()))),
    );

    useEffect(() => {
      let timer;
      if (scheduleTo) {
        if (miliseconds > 0) {
          timer = setTimeout(() => {
            setMiliseconds(
              Number(moment.duration(moment(scheduleTo).diff(moment()))),
            );
          }, 100);
        } else if (miliseconds <= 0) {
          clearTimeout(timer);
          setMiliseconds(0);
          if (!submittedOnce) {
            setTimeUpModal(true);
          }
        }

        // for stopping timer if user submitted before time is up
        if (modalSuccess) {
          clearTimeout(timer);
        }
      }
      return () => clearTimeout(timer);
    }, [miliseconds, scheduleTo]);

    // conerts milisecods to HH:MM:SS
    if (miliseconds) {
      const time = new Date(miliseconds).toISOString().substr(11, 8);
      return <span style={{ fontVariantNumeric: 'tabular-nums' }}>{time}</span>;
    }
    return null;
  };

  const RenderPaginationButton = ({ totalQuestion = null, item }) => {
    // list of unanswered questions

    const skippedQuestions = item.test_items.filter((test_item) => {
      const skipped = !Object.keys(inputs).find((key) => {
        return inputs[key].test_itemId === test_item.id;
      });
      if (skipped) return true;
      return false;
    });

    const handleSkipQuestions = () => {
      const newInputs = { ...inputs };

      const formattedAnswers = skippedQuestions.map((question) => ({
        [`answer${question.id}`]: {
          test_itemId: question.id,
          user_answer: 'user skipped',
        },
      }));

      formattedAnswers.forEach((answer) => {
        const key = Object.keys(answer)[0];
        newInputs[key] = answer[key];
      });

      const listQuestion = data.test_item_headers.map(
        (questionItem) => questionItem.test_items[0],
      );

      const listUserAnswer = getListUserAnswerFromInputs(newInputs);

      const countAnswer = getListIndexAnsweredByUser(
        listUserAnswer,
        listQuestion,
      ).length;
      localStorage.setItem(
        `countUserAnswerQuestionTest${testId}_userId${userId}`,
        countAnswer,
      );
      setInputs(newInputs);
      setCountUserAnswer(countAnswer);
      localStorage.setItem(
        `inputs${testId}_userId${userId}`,
        JSON.stringify(newInputs),
      );
      saveQuestionPosition(questionNumber + 1);
      setSkipModal(false);
    };

    const handleNextButton = () => {
      if (skippedQuestions.length) {
        setSkipModal(true);
      } else {
        saveQuestionPosition(questionNumber + 1);
      }
    };

    return (
      <div>
        {questionNumber !== totalQuestion - 1 && (
          <PButton
            className="outline-0"
            onClick={handleNextButton}
            disabled={loadingButton}
          >
            Next -&gt;
          </PButton>
        )}
        <SkipModal
          isOpen={skipModal}
          toggle={() => setSkipModal(!skipModal)}
          handleSkipQuestions={handleSkipQuestions}
        />
      </div>
    );
  };

  const RenderTitleTest = () => {
    return (
      <div className="card title-quiz mt-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <div>
              <h4 className="mb-0 font-weight-bold text-left">
                Problem : {questionNumber + 1} / {data.totalProblems}
              </h4>
            </div>
            <div>
              <h4>{data?.duration && <RenderCountDown />}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderButtonSubmit = () => {
    if (loadingButton) {
      return (
        <PButton
          style={{
            width: '100%',
            outline: '0',
          }}
          disabled
        >
          <Loader type="ThreeDots" color="#FFF" height={10} width={10} />
        </PButton>
      );
    }
    if (data.totalQuestions > countUserAnswer) {
      return (
        <PButton
          style={{
            width: '100%',
            outline: '0',
          }}
          disabled
        >
          Submit Answers
        </PButton>
      );
    }

    return (
      <PButton
        style={{
          width: '100%',
          outline: '0',
        }}
        className="mt-3"
        type="submit"
        onClick={submitAnswers}
      >
        Submit Answers
      </PButton>
    );
  };

  if (!data || disableAccess) {
    return <Redirect to="/not-found" />;
  }
  if (submitDone) {
    return <Redirect to="/home" />;
  }
  if (!userId) {
    return <Redirect to="/" />;
  }
  if (loading && !disableAccess) {
    return (
      <div className="container mt-5">
        <div className="row h-100">
          <div className="col h-100">
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <Loader type="ThreeDots" color="#FFF" height={100} width={100} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (!disableAccess) {
    // questionNumber + 1 karena start from 0
    const isLastQuestion =
      data?.test_item_headers?.length === questionNumber + 1;
    return (
      <div className="container" style={{ minHeight: '100vh' }}>
        <RenderTitleTest />
        <SuccessModal
          isOpen={modalSuccess}
          toggle={() => setSubmitDone(true)}
        />
        <TimeUpModal isOpen={timeUpModal} toggle={toggleTimeUpModal} />
        <div className="card content-quiz">
          {data.test_item_headers?.map((item, index) => {
            // loops through test_item_headers
            if (index === questionNumber) {
              return (
                <Card key={item.id} className="mb-3">
                  <TestItem
                    key={item.id}
                    inputs={inputs}
                    handleChange={handleChange}
                    item={item}
                    index={index}
                    handleStringChange={handleStringChange}
                    componentSubmit={isLastQuestion && <RenderButtonSubmit />}
                    componentPagination={
                      <RenderPaginationButton
                        totalQuestion={data.totalProblems}
                        item={item}
                      />
                    }
                  />
                </Card>
              );
            }

            return null;
          })}
        </div>
      </div>
    );
  }
  return <></>;
};

export default TestPage;
