// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import moment from 'moment';
import { API_URL_1 } from '../../supports/api-url/apiurl';
import { randomizeArray } from '../../helpers/randomizeArray';

const initialState = {
  loading: false,
  headerData: [],
  data: [],
  error: false,
  message: '',
  countAnswer: 0,
};

const { actions, reducer } = createSlice({
  name: 'test',
  initialState,
  reducers: {
    fetchTestStart: (state) => {
      state.loading = true;
    },
    setTestData: (state, action) => {
      state.data = action.payload;
    },
    setHeaderData: (state, action) => {
      state.headerData = action.payload;
    },
    fetchTestSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    fetchTestFailed: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
    assignCountUserAnswer: (state, action) => {
      state.countAnswer = action.payload;
    },
    resetTestDataState: (state) => {
      state.countAnswer = 0;
      state.data = [];
    },
  },
});

export default reducer;

export const {
  fetchTestFailed,
  fetchTestStart,
  fetchTestSuccess,
  setTestData,
  setHeaderData,
  assignCountUserAnswer,
  resetTestDataState,
} = actions;

export const fetchTestById = (testId, userId) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    dispatch(fetchTestStart());
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data: resultOrderTestUser } = await Axios.get(
        `${API_URL_1}/order-test/${testId}`,
        options,
      );

      const resultTestData = await Axios.get(`${API_URL_1}/test/${testId}`);

      const {
        description,
        title,
        totalQuestions,
        isEssay,
        test_item_headers: listQuestion,
        duration,
      } = resultTestData.data.data;

      const dataTests = {
        testId,
        description,
        title,
        totalQuestions,
        isEssay,
        test_item_headers: null,
        totalProblems: null,
        duration,
      };

      if (resultOrderTestUser.dataOrder) {
        // apabila tidak kosong / sudah memiliki order soal yang telah diacak
        const { orderTest } = resultOrderTestUser.dataOrder;

        dataTests.test_item_headers = JSON.parse(orderTest);
        dataTests.totalProblems = JSON.parse(orderTest).length;
      } else {
        // apabila kosong, maka akan membuat order random soal dan save ke database.
        const dataOrderTest = {
          testId,
          orderTest: JSON.stringify(randomizeArray(listQuestion)),
        };

        await Axios.post(`${API_URL_1}/order-test/`, dataOrderTest, options);

        dataTests.test_item_headers = JSON.parse(dataOrderTest.orderTest);
        dataTests.totalProblems = JSON.parse(dataOrderTest.orderTest).length;
      }

      dispatch(setTestData(dataTests));

      const existingShouldEndAt = localStorage.getItem(
        `shouldEndAtTest${testId}_userId${userId}`,
      );
      if (!existingShouldEndAt) {
        const shouldEndAt = moment()
          .add(dataTests.duration, 'milliseconds')
          .format();
        localStorage.setItem(
          `shouldEndAtTest${testId}_userId${userId}`,
          shouldEndAt,
        );
      }

      dispatch(fetchTestSuccess(resultTestData.data.message));
    } catch (err) {
      dispatch(fetchTestFailed());
      window.alert(err);
    }
  };
};

export const fetchAllTestHeader = () => {
  return async (dispatch) => {
    dispatch(fetchTestStart());
    try {
      const titles = await Axios.get(`${API_URL_1}/test/`);
      dispatch(setHeaderData(titles.data.data));
      dispatch(fetchTestSuccess(titles.data.message));
    } catch (err) {
      dispatch(fetchTestFailed());
      window.alert(err);
    }
  };
};
